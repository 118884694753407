<template>
  <div>
    <label>Typ banera</label>
    <fg-input>
      <el-select
        v-model="chanelTypes"
        name="chanelTypes"
        filterable
        clearable
        multiple
      >
        <el-option v-for="item in chanelTypesList" :label="item"
                   :key="item" :value="item"></el-option>
      </el-select>
    </fg-input>
  </div>
</template>
<script>


import {Option, Select} from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      chanelTypes: [],
      chanelTypesList: ['Display','Mailingi wewnętrzne','Mailingi zewnętrzne', 'INNY', 'SMS']
    };
  },
  computed: {
  },
  methods: {
    async loadData(force) {
    },

  },
  mounted() {
  },
  watch: {
    chanelTypes: function() {
      // Emit this information to the parents component
      this.$emit("chanelTypes", this.chanelTypes);
    }
  },
  created() {
  },
  beforeDestroy: function () {

  },
};

</script>
