<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Walidacja rekordów nie sprzedanych</h5>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-8 col-lg-6  text-md-right">
            <label>Data zebrania leada</label><br>
            <el-date-picker
              v-model="dateRange"
              type="datetimerange"
              align="center"
              unlink-panels
              range-separator="-"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-mh-dict-view @media_house="changeMh"></app-mh-dict-view>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-banner-search-view @banners="changeBanners"></app-banner-search-view>
          </div>
          <div class="offset-md-4 offset-lg-3 col-md-4 col-lg-3 text-right">
            <app-project-dict-view @projects="changeProjects"></app-project-dict-view>
          </div>
          <div class="offset-md-4 offset-lg-3 col-md-4 col-lg-3 text-right">
            <app-chanel-types-dic-view @chanelTypes="chanelTypes"></app-chanel-types-dic-view>
          </div>
          <div class="col-md-6 text-right mt-3">
            <p-button type="success" @click.prevent="loadData()">Wczytaj</p-button>
            <p-button type="success" style="margin-left: 5px;" @click.prevent="loadData('excel')">Excel</p-button>
          </div>
        </div>

        <div class="row m-2" v-if="Object.keys(filterTableList).length > 0">
          <div class="col-12 p-0">
            <fieldset class="rounded border-1">
              <legend>Filtry</legend>
              <div class="row">
                <div class="col-sm-6 col-md-2" v-for="(filterValue,filterName) in filterTableList">

                  <el-select v-model="filterTableListValue[filterName]" multiple :placeholder=filterName
                             @change="filterTableMake()" class="mt-2">
                    <el-option
                      v-for="item in filterValue"
                      :key="item.text"
                      :label="item.text"
                      :value="item.text">
                    </el-option>
                  </el-select>
                </div>
                <div class="col">
                  <p-button type="success" @click.prevent="filterClearTableMake()">Wyczyść</p-button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table ref="filterTable" :data="notSoldDataDisplay" style="width: 100%" @sort-change="sortChange">
          <el-table-column label="Wydawca" prop="MediaHouse" width="150" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Wydawca Id" width="170" prop="MediaHouseID" sortable="custom"

          ></el-table-column>
          <el-table-column label="Banner" prop="Banner" width="250" type="str" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Banner ID" width="180" prop="BannerID" sortable="custom"
          ></el-table-column>
          <el-table-column label="Projekt" prop="Project" width="250" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Projekt Id	" prop="ProjectID" width="160" sortable="custom"

          ></el-table-column>
          <el-table-column label="Moduł" prop="Modul" width="140" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Kanał" prop="Kanal" width="250" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Kanał ID" prop="KanalID" width="150" sortable="custom"
          ></el-table-column>
          <el-table-column label="Komunikat" prop="Komunikat" width="150" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Leady" prop="Leady" width="120" sortable="custom"
          ></el-table-column>
          <el-table-column label="Data Rejestracji" width="210" prop="DataRejestracji" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
          <el-table-column label="Typ Banera" width="170" prop="TypBannera" sortable="custom"
                           class-name="strSort"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="card-body row">
      <div class="col-sm-6 pagination-info">
        <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                      :total="pagination.total"></p-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import utils from "src/utils";

// event bus
import {eventBus} from "src/main";

import {Select, DatePicker, Pagination, Option, OptionGroup} from "element-ui";
import ProjectDictView from "@/components/Utils4/formsElementView/projectDict.vue";
import BannerSearchView from "@/components/Utils4/formsElementView/bnnerSearch.vue"
import MhDictView from "@/components/Utils4/formsElementView/mhDict.vue";
import ChanelTypesDicView from "@/components/Utils4/formsElementView/chanelDict.vue";


import PPagination from "@/components/UIComponents/Pagination.vue";
import {COLLECTED_NOT_SOLD_INVALID, LOAD_COLLECTED_NOT_SOLD} from "@/store/actions/raports/collected_not_sold";
import {
  COLLECTED_NOT_SOLD_EXCEL_INVALID,
  LOAD_COLLECTED_NOT_SOLD_EXCEL
} from "@/store/actions/raports/collected_not_sold_excel";


export default {
  components: {
    PPagination,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [Pagination.name]: Pagination,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    appProjectDictView: ProjectDictView,
    appBannerSearchView: BannerSearchView,
    appMhDictView: MhDictView,
    appChanelTypesDicView: ChanelTypesDicView
  },
  data() {
    return {
      loading: false,
      listNotSoldDataPresent: [],
      filterListNotSoldDataPresent: {},
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      filterTableList: {},
      filterTableListValue: {},
      filter: {
        media_house: null,
        banners: [],
        projects: [],
        chanelTypes: [],
      },
      dateRange: [moment(utils.today()).format("YYYY-MM-DD 00:00:00"), moment(utils.today()).format("YYYY-MM-DD 23:59:59")],
      pickerOptions: {
        selectableRange: '18:30:00 - 20:30:00',
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    filterClearTableMake() {
      this.listNotSoldDataPresent = [];
      this.filterTableListValue = {};
      this.notSoldDataPresent = [];
      this.$refs.filterTable.clearSort();
    },
    filterTableMake() {
      this.listNotSoldDataPresent = [];
      let changeListNotSoldDataPresent = [], noData = [];

      for (const [key, value] of Object.entries(this.notSoldDataPresent)) {
        if (key === '0') {
          let noDataElement = {};
          for (const keyE in value) {
            noDataElement[keyE] = 'Brak Danych';
          }
          noData.push(noDataElement);
        }
        let isRecord = true;
        for (const [keyF, valueF] of Object.entries(this.filterTableListValue)) {
          if (valueF.length > 0) {
            if (!valueF.includes(value[keyF])) {
              isRecord = false;
            }
          }
        }
        if (isRecord === true) {
          changeListNotSoldDataPresent.push(value);
        }
      }
      if (changeListNotSoldDataPresent.length > 0) {
        this.pagination.total = changeListNotSoldDataPresent.length;
        this.notSoldDataPresent = changeListNotSoldDataPresent;
        this.$refs.filterTable.clearSort();
      } else {
        this.notSoldDataPresent = noData;
      }
    },
    async loadData(mode) {
      this.filterListNotSoldDataPresent = [];

      this.filterClearTableMake();

      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
        baners: this.filter.banners,
        mediahouse: this.filter.media_house,
        chanelTypes: this.filter.chanelTypes,
        projects: this.filter.projects
      };

      this.$store.commit(mode === "excel" ? COLLECTED_NOT_SOLD_EXCEL_INVALID : COLLECTED_NOT_SOLD_INVALID);

      await this.$store
        .dispatch(mode === "excel" ? LOAD_COLLECTED_NOT_SOLD_EXCEL : LOAD_COLLECTED_NOT_SOLD, filters)
        .then(() => {

          let message = 'Wczytano dane';
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        })
        .catch((error) => {
          let isJson;
          let msg = "";
          let err_msg;
          try {
            isJson = true;
            err_msg = JSON.parse(error.response.data.description);
          } catch (e) {
            isJson = false
          }
          if (isJson === true) {
            if ("missing" in err_msg) {
              msg = "Brak parametru: " + err_msg.missing;
            }
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getCollectedNotSoldExcel.tmpname;

        const fileName = this.$store.getters.getCollectedNotSoldExcel.name;

        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-utils4.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.slice(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    changeProjects(projects) {
      this.filter.projects = projects;
    },
    chanelTypes(chanels) {
      this.filter.chanelTypes = chanels;
    },
    changeBanners(banners) {
      this.filter.banners = banners;
    },
    changeMh(media_house) {
      this.filter.media_house = media_house;
    },
    sortChange(obj) {
      let result = [...this.notSoldDataPresent];
      let sorStr = false;
      if (obj.column.hasOwnProperty('className')) {
        sorStr = obj.column.className.includes('strSort');
      }

      if (obj.order === "descending") {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      }
      this.notSoldDataPresent = result;
    },
  },

  computed: {

    notSoldData() {
      if (this.listNotSoldDataPresent.length === 0 && this.$store.state.collected_not_sold.loading_status === "success") {
        this.listNotSoldDataPresent = this.$store.getters.getCollectedNotSold;

        if (this.listNotSoldDataPresent.length > 0) {
          this.filterTableList = {};
          Object.keys(this.listNotSoldDataPresent[0]).map(key => {
              this.filterTableList[key] = [...new Map(this.listNotSoldDataPresent.map(item =>
                [item[key], {text: item[key], value: item[key]}])).values()];
            }
          );
        }
      }


      return this.listNotSoldDataPresent;
    },
    notSoldDataPresent: {
      get() {
        let result = this.notSoldData;
        if (Array.isArray(result)) {
          this.pagination.total = result.length;
          return result;
        } else {
          result = [];
        }
        return result;
      },
      set(newValue) {
        this.listNotSoldDataPresent = newValue;
      }
    },
    notSoldDataDisplay() {
      return this.notSoldDataPresent.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.notSoldData.length;
      return this.notSoldData.length;
    },

  },
  created() {
  },
  beforeDestroy: function () {
    this.$store.commit(COLLECTED_NOT_SOLD_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}
fieldset{
  border:1px solid #ccc;
  padding-left: 10px;
  padding-right: 10px;
}
legend{
  width: auto;
  margin-left: 30px;
  background-color: transparent;
}
</style>
